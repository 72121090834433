// Generated by Framer (552ec80)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/H1sSqOztj";

const serializationHash = "framer-4CqcH"

const variantClassNames = {HlfxUmrU_: "framer-v-1ky3d8q"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, text, width, ...props}) => { return {...props, nF6YNQwbo: text ?? props.nF6YNQwbo ?? "Laborum cupidatat occaecat proident qui deserunt sint officia mollit culpa id Excepteur non est sunt anim in accumsan cursus ante primis Phasellus Vestibulum et orci velit ipsum ultrices cubilia in posuere faucibus luctus curae arcu nec odio fermentum Fusce velit."} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;text?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, nF6YNQwbo, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "HlfxUmrU_", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1ky3d8q", className, classNames)} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"HlfxUmrU_"} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-bc567dab-a014-498f-95c5-57b3fd26b32c, rgb(1, 31, 64))", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 6, borderBottomRightRadius: 6, borderTopLeftRadius: 6, borderTopRightRadius: 6, boxShadow: "0.47422285992186514px 0.47422285992186514px 0.6706524000888582px -0.75px rgba(0, 0, 0, 0.1838), 1.2914347811369224px 1.2914347811369224px 1.8263645824041657px -1.5px rgba(0, 0, 0, 0.17741), 2.8355233674636113px 2.8355233674636113px 4.010035602692868px -2.25px rgba(0, 0, 0, 0.16535), 6.294214453822934px 6.294214453822934px 8.901363445081158px -3px rgba(0, 0, 0, 0.13833), 16px 16px 22.627416997969522px -3.75px rgba(0, 0, 0, 0.0625)", ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-a4xuu5"} data-styles-preset={"H1sSqOztj"}>Laborum cupidatat occaecat proident qui deserunt sint officia mollit culpa id Excepteur non est sunt anim in accumsan cursus ante primis Phasellus Vestibulum et orci velit ipsum ultrices cubilia in posuere faucibus luctus curae arcu nec odio fermentum Fusce velit.</motion.p></React.Fragment>} className={"framer-1rny2zk"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"SECu42nxc"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={nF6YNQwbo} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-4CqcH.framer-1gpahx0, .framer-4CqcH .framer-1gpahx0 { display: block; }", ".framer-4CqcH.framer-1ky3d8q { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 32px; height: min-content; justify-content: center; overflow: hidden; padding: 16px; position: relative; width: 240px; will-change: var(--framer-will-change-override, transform); }", ".framer-4CqcH .framer-1rny2zk { flex: none; height: auto; position: relative; white-space: pre-wrap; width: 100%; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-4CqcH.framer-1ky3d8q { gap: 0px; } .framer-4CqcH.framer-1ky3d8q > * { margin: 0px; margin-bottom: calc(32px / 2); margin-top: calc(32px / 2); } .framer-4CqcH.framer-1ky3d8q > :first-child { margin-top: 0px; } .framer-4CqcH.framer-1ky3d8q > :last-child { margin-bottom: 0px; } }", ...sharedStyle.css, ".framer-4CqcH[data-border=\"true\"]::after, .framer-4CqcH [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 341
 * @framerIntrinsicWidth 240
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"nF6YNQwbo":"text"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerelNc3tG6Z: React.ComponentType<Props> = withCSS(Component, css, "framer-4CqcH") as typeof Component;
export default FramerelNc3tG6Z;

FramerelNc3tG6Z.displayName = "example description tag";

FramerelNc3tG6Z.defaultProps = {height: 341, width: 240};

addPropertyControls(FramerelNc3tG6Z, {nF6YNQwbo: {defaultValue: "Laborum cupidatat occaecat proident qui deserunt sint officia mollit culpa id Excepteur non est sunt anim in accumsan cursus ante primis Phasellus Vestibulum et orci velit ipsum ultrices cubilia in posuere faucibus luctus curae arcu nec odio fermentum Fusce velit.", displayTextArea: false, title: "Text", type: ControlType.String}} as any)

addFonts(FramerelNc3tG6Z, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})